<script setup lang="ts">
  import {computed, watch} from 'vue';
  import useCpBem from '~/composables/useCpBem';

  const props = withDefaults(defineProps<{
    show: boolean
    alignItems?: boolean
    fullscreen?: boolean
    persistent?: boolean
    withoutEnterAnimation?: boolean
    withoutLeaveAnimation?: boolean
    scrollStrategy?: 'close'|'block'
    dataTestId?: string
    // Render content when it mounts
    eager?: boolean
    dataTheme?: string
    withoutBorders?: boolean
  }>(), {
    fullscreen: false,
    persistent: false,
    withoutEnterAnimation: false,
    withoutLeaveAnimation: false,
    scrollStrategy: 'close',
    dataTestId: 'modal-dialog',
    dataTheme: undefined,
  });

  const emit = defineEmits<{
    (e: 'update:show', id: boolean): void
    (e: 'hidden'): void
  }>();

  const value = computed({
    get() {
      return props.show;
    },
    set(value: boolean) {
      emit('update:show', value);
    },
  });

  watch(() => props.show, (newValue: boolean) => {
    if (!newValue) {
      emit('hidden');
    }
  });

  const {bm, em} = useCpBem('cp-modal');
</script>

<template>
    <v-dialog
        v-model="value"
        :class="bm({ hideEnterAnimation: withoutEnterAnimation, hideLeaveAnimation: withoutLeaveAnimation })"
        :fullscreen="fullscreen"
        :persistent="persistent"
        :scroll-strategy="scrollStrategy"
        transition="dialog-bottom-transition"
        no-click-animation
        :retain-focus="false"
        :eager="eager"
        :scrollable="true"
        :data-theme="dataTheme"
    >
        <div :class="em('container', {alignItems, fullscreen, withoutBorders})" :data-testid="dataTestId">
            <slot />
        </div>
    </v-dialog>
</template>
<style scoped lang="scss">
  @import "assets/style/global.scss";

  .cp-modal {
    &--hide-enter-animation {
      &:deep(.dialog-bottom-transition-enter-active) {
        transition-duration: 0s !important;
      }
    }

    &--hide--leave-animation {
      &:deep(.dialog-bottom-transition-leave-active) {
        transition-duration: 0s !important;
      }
    }

    &:deep(.v-overlay__content) {
      bottom: 0;
      margin: 0;
      width: 100%;
      max-width: none;
    }

    &:deep(.v-overlay__scrim) {
      background: var(--color-overlay-standard);
      opacity: 1;
    }

    &__container {
      display: flex;
      flex-direction: column;
      background-color: var(--background-color);
      overflow: hidden;
      border-top-right-radius: $cp-gutter-small;
      border-top-left-radius: $cp-gutter-small;
      line-height: 0;
      &--align-items {
        align-items: center;
      }
      &--fullscreen {
        height: 100%;
        border-radius: unset;
      }
      &--without-borders {
        border-radius: 0;
      }
    }
  }
</style>
